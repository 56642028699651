<script>
import { emailIsValid } from "@/utils/EmailValidator.js";
import { OnClickOutside } from "@vueuse/components";

const steps = {
    DEFAULT: 0,
    FORM: 1,
    CONFIRMATION: 2,
};

export default {
    name: "shopping-context",

    provide() {
        return {
            registerShoppingItem: this.registerShoppingItem,
            selectBike: this.selectBike,
            bikeSelected: this.bikeSelected,
            selectWheels: this.selectWheels,
            deselectWheels: this.deselectWheels,
            wheelsSelected: this.wheelsSelected,
        };
    },

    /*
    formSuccessMessage
    errorFormFieldRequired
    errorFormCouldNotSent
    */
    components: {
        OnClickOutside,
    },

    props: {
        texts: Object,
    },

    data() {
        return {
            shoppingItems: [],
            cartOpen: false,
            stepAnimationDirection: "forward",

            selectedBike: null,
            selectedWheels: false,

            error: null,
            success: null,
            loading: false,
            contactName: "",
            contactEmail: "",
            contactPhone: "",
            contactDisclaimer: false,

            currentStep: steps.DEFAULT,
            steps: steps,
        };
    },

    computed: {
        lacqueringAvailable() {
            const { showLacquering } = this.selectedBike || {
                showLacquering: false,
            };

            return showLacquering;
        },

        selectedBikePrice() {
            const { price: bikePrice } = this.selectedBike || { price: 0 };

            return parseInt(bikePrice);
        },

        selectedLacqueringPrice() {
            return this.lacqueringAvailable ? 1500 : 0;
        },

        selectedWheelPrice() {
            return this.selectedWheels ? 3500 : 0;
        },

        totalPrice() {
            return (
                this.selectedBikePrice +
                this.selectedLacqueringPrice +
                this.selectedWheelPrice
            );
        },

        isFormButtonDisabled() {
            return (
                !this.contactName ||
                !this.contactEmail ||
                this.emailInvalid ||
                !this.contactDisclaimer
            );
        },

        isCartButtonDisabled() {
            return !this.selectedBike;
        },

        isInputDisabled() {
            return this.loading || (this.success && !this.error);
        },

        emailInvalid() {
            return (
                !!this.contactEmail.trim().length &&
                !emailIsValid(this.contactEmail)
            );
        },

        emailLabelClass() {
            return {
                "form__input--invalid":
                    this.contactEmail.length > 0 &&
                    !emailIsValid(this.contactEmail),
            };
        },

        stepAnimationName() {
            return `inline-cart-${this.stepAnimationDirection}`;
        },

        hasRegisteredWheelSection() {
            const wheelSection = this.shoppingItems.find(
                (i) => i.type == "wheels"
            );

            return wheelSection ? true : false;
        },
    },

    methods: {
        async handleSubmit() {
            if (this.isFormButtonDisabled) {
                return;
            }

            this.error = null;
            this.success = false;
            this.loading = true;

            var bodyFormData = new FormData();
            bodyFormData.set(
                "payload",
                JSON.stringify({
                    name: this.contactName,
                    email: this.contactEmail,
                    phone: this.contactPhone,
                    bikeName: this.selectedBike.name,
                    bikePrice: this.selectedBike.price,
                    bikeWheels: this.selectedWheels,
                    disclaimer: this.contactDisclaimer,
                })
            );

            const { data } = await this.$axios.post(
                "/actions/site-module/shop-form/submit",
                bodyFormData
            );

            // const data = {
            //     // error: 'errorFormFieldRequired',
            //     success: true
            // };

            if (data.hasOwnProperty("error")) {
                this.error = data.error;
            }

            this.loading = false;
            this.success = true;
            this.goForward();
        },

        registerShoppingItem(item) {
            this.shoppingItems.push(item);
        },

        selectBike(item) {
            this.selectedBike = item;
            this.cartOpen = true;
        },

        bikeSelected(item) {
            if (this.selectedBike === null) {
                return false;
            }

            if (this.selectedBike.id === item.id) {
                return true;
            }

            return false;
        },

        selectWheels() {
            this.selectedWheels = true;
            this.cartOpen = true;
        },

        deselectWheels() {
            this.selectedWheels = false;
        },

        wheelsSelected() {
            return this.selectedWheels;
        },

        toggleCart() {
            this.cartOpen = !this.cartOpen;
        },

        getFormattedPrice(number) {
            const lang = document.documentElement.getAttribute("lang") || "";
            return `${parseInt(number).toLocaleString(lang)}€`;
        },

        t(key, fallback) {
            if (!this.texts || !this.texts.hasOwnProperty(key)) {
                return fallback != null ? fallback : key;
            }

            return this.texts[key];
        },

        goBack() {
            this.stepAnimationDirection = "back";
            switch (this.currentStep) {
                case this.steps.FORM:
                    this.currentStep = this.steps.DEFAULT;
                    break;
            }
        },

        goForward() {
            this.stepAnimationDirection = "forward";
            switch (this.currentStep) {
                case this.steps.DEFAULT:
                    this.currentStep = this.steps.FORM;
                    break;
                case this.steps.FORM:
                    this.currentStep = this.steps.CONFIRMATION;
                    break;
            }
        },
    },
};
</script>

<template>
    <slot />

    <div class="inline-cart" :class="{ 'inline-cart--open': cartOpen }">
        <OnClickOutside @trigger="cartOpen = false">
            <span
                v-if="shoppingItems.length > 0"
                class="inline-cart__button"
                @click="toggleCart"
            >
                <template v-if="cartOpen"
                    ><slot name="icon-cart-close"
                /></template>
                <template v-else><slot name="icon-cart-open" /></template>
            </span>

            <transition name="inline-cart-toggle">
                <div v-if="cartOpen" class="inline-cart__steps-wrapper">
                    <transition-group
                        :name="stepAnimationName"
                        tag="div"
                        class="inline-cart__steps"
                    >
                        <div
                            v-if="currentStep == steps.DEFAULT"
                            class="inline-cart__step"
                        >
                            <div class="inline-cart__step-content">
                                <h3 class="inline-cart__step-headline">
                                    {{ t("shopLabelHeadline") }}
                                </h3>

                                <table class="inline-cart__step-table">
                                    <tr>
                                        <td>
                                            <a
                                                v-if="selectedBike === null"
                                                href="#select-bike"
                                                @click="cartOpen = false"
                                                >{{ t("shopLabelBase") }}</a
                                            >
                                            <span v-else>
                                                {{ selectedBike.name }}
                                                <a
                                                    href="#select-bike"
                                                    class="small"
                                                    >{{
                                                        t("shopLabelChangeBase")
                                                    }}</a
                                                >
                                            </span>
                                        </td>
                                        <td>
                                            {{
                                                getFormattedPrice(
                                                    selectedBikePrice
                                                )
                                            }}
                                        </td>
                                    </tr>
                                    <tr v-if="lacqueringAvailable">
                                        <td>{{ t("shopLabelLacquering") }}</td>
                                        <td>
                                            {{
                                                getFormattedPrice(
                                                    selectedLacqueringPrice
                                                )
                                            }}
                                        </td>
                                    </tr>
                                    <tr v-if="hasRegisteredWheelSection">
                                        <td>
                                            {{ t("shopLabelWheels") }}
                                            <a
                                                href="#"
                                                v-if="selectedWheels"
                                                class="small"
                                                @click.prevent="deselectWheels"
                                                >{{ t("shopLabelRemove") }}</a
                                            >
                                            <a
                                                href="#"
                                                v-else
                                                class="small"
                                                @click.prevent="selectWheels"
                                                >{{ t("shopLabelAdd") }}</a
                                            >
                                        </td>
                                        <td>
                                            {{
                                                getFormattedPrice(
                                                    selectedWheelPrice
                                                )
                                            }}
                                        </td>
                                    </tr>
                                    <tr class="inline-cart__step-table-total">
                                        <td>{{ t("shopLabelTotal") }}</td>
                                        <td>
                                            {{ getFormattedPrice(totalPrice) }}
                                        </td>
                                    </tr>
                                </table>
                                <levelx-button
                                    @click.prevent="goForward"
                                    :disabled="isCartButtonDisabled"
                                    variant="outlined"
                                    style="primary"
                                >
                                    {{ t("shopLabelNextStep") }}
                                </levelx-button>
                            </div>
                        </div>

                        <div
                            v-if="currentStep == steps.FORM"
                            class="inline-cart__step"
                        >
                            <div class="inline-cart__step-content">
                                <div
                                    v-if="!loading"
                                    class="inline-cart__step-back"
                                    @click="goBack"
                                >
                                    <slot name="icon-back" />
                                </div>
                                <h3 class="inline-cart__step-headline">
                                    {{ t("shopLabelHeadlineForm") }}
                                </h3>

                                <form class="shopping-form form">
                                    <input
                                        class="form__input"
                                        type="text"
                                        id="shopping-form__name"
                                        name="name"
                                        :disabled="isInputDisabled"
                                        :placeholder="t('namePlaceholder', '')"
                                        v-model="contactName"
                                    />

                                    <input
                                        :class="[
                                            'form__input',
                                            emailLabelClass,
                                        ]"
                                        type="email"
                                        id="shopping-form__email"
                                        name="email"
                                        :disabled="isInputDisabled"
                                        :placeholder="t('emailPlaceholder', '')"
                                        v-model="contactEmail"
                                    />

                                    <input
                                        class="form__input"
                                        type="text"
                                        id="shopping-form__phone"
                                        name="phone"
                                        :disabled="isInputDisabled"
                                        :placeholder="t('phonePlaceholder', '')"
                                        v-model="contactPhone"
                                    />

                                    <div
                                        class="form__checkbox-label"
                                        id="shopping-form__disclaimer"
                                    >
                                        <input
                                            class="form__checkbox"
                                            type="checkbox"
                                            id="contact-form__disclaimer-checkbox"
                                            :disabled="isInputDisabled"
                                            v-model="contactDisclaimer"
                                        />
                                        <label
                                            class="text"
                                            for="shopping-form__disclaimer"
                                            v-html="t('disclaimerLabel')"
                                        />
                                    </div>

                                    <levelx-button
                                        id="contact-submit-button"
                                        @click.prevent="handleSubmit"
                                        variant="solid"
                                        style="primary"
                                        :loading="loading"
                                        :disabled="isFormButtonDisabled"
                                        icon-position="right"
                                    >
                                        <template #loading-indicator
                                            ><slot name="loading-icon"
                                        /></template>
                                        {{ t("shopLabelFormSubmit") }}
                                    </levelx-button>
                                </form>
                            </div>
                        </div>

                        <div
                            v-if="currentStep == steps.CONFIRMATION"
                            class="inline-cart__step"
                        >
                            <div class="inline-cart__step-image">
                                <slot name="confirmation-image" />
                            </div>
                            <div class="inline-cart__step-content">
                                <h3 class="inline-cart__step-headline">
                                    {{ t("shopLabelHeadlineConfirmation") }}
                                </h3>
                                <p>{{ t("shopLabelConfirmation") }}</p>
                                <levelx-button
                                    @click.prevent="cartOpen = false"
                                    variant="outlined"
                                    style="primary"
                                >
                                    {{ t("shopLabelButtonClose") }}
                                </levelx-button>
                            </div>
                        </div>
                    </transition-group>
                </div>
            </transition>
        </OnClickOutside>
    </div>
</template>

<style lang="scss">
@use "@bam/scss" as *;

.inline-cart {
    position: fixed;
    z-index: 5;
    bottom: 42px;
    right: 16px;

    @include mq-from(sm) {
        top: 50%;
        right: v(size-42);
        bottom: auto;
    }

    &__button {
        position: relative;
        width: 42px;
        height: 42px;
        background-color: v(color-primary);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transform: translateY(-50%);

        svg {
            transition: transform v(easing-default) v(duration-default);
            width: 20px;
            height: 20px;
        }

        @include hover {
            svg {
                transform: scale(1.3);
            }
        }

        @include mq-from(sm) {
            width: 60px;
            height: 60px;

            svg {
                width: 25px;
                height: 25px;
            }
        }
    }

    &__steps-wrapper {
        position: fixed;
        right: 0;
        top: 0;
        bottom: 0;
        left: 0;

        background-color: v(color-black);
        color: v(color-light);

        @include mq-from(sm) {
            left: auto;
            width: 370px;
        }

        &:before {
            content: ">";
            width: 30px;
            background-color: v(color-primary);
            color: v(color-black);
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            pointer-events: none;
            position: absolute;
            left: -30px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 30px;
            border-radius: 40px 0 0 40px;
            padding-left: 6px;
        }
    }

    &__steps {
        max-height: 100%;
        overflow: auto;
        position: relative;
    }

    &__step {
        &-headline {
            font: v(font-heading-3);
            text-transform: uppercase;
            color: v(color-headline);
            margin: 0;
            margin-bottom: v(size-16);
        }

        &-back {
            position: absolute;
            top: v(size-16);
            cursor: pointer;
            color: v(color-white);

            svg {
                @include svg-auto-color;
            }
        }

        &-content {
            padding: v(size-42) v(size-16) v(size-16) v(size-16);

            @include mq-from(sm) {
                padding: v(size-68) v(size-16) v(size-16) v(size-16);
            }

            .levelx-button {
                margin-top: v(size-16);
            }
        }

        &-image {
            width: 100%;
            height: auto;
        }

        &-table {
            width: 100%;
            border-collapse: collapse;

            a {
                display: block;
                text-decoration: none;
                color: v(color-primary);
                transition: color v(easing-default) v(duration-default);

                @include hover {
                    color: v(color-white);
                }

                &.small {
                    font-size: 12px;
                }
            }

            tr {
                border-top: 1px solid v(color-silver-chalice);

                &:first-child {
                    border-top: 0;
                }
            }

            td {
                font: v(font-table);
                padding-top: v(size-16);
                padding-bottom: v(size-16);
                vertical-align: top;
            }

            tr td:first-child {
                @include font-smoothing;
                color: v(color-silver-chalice);
            }

            tr td:last-child {
                @include font-smoothing;
                text-align: right;
            }

            & .inline-cart__step-table-total {
                border-top-color: v(color-white);

                td:first-child {
                    color: v(color-white);
                }
                td:last-child {
                    font-weight: bold;
                }
            }
        }

        .form {
            display: flex;
            flex-flow: column;
            gap: 20px;

            .form__label,
            .form__checkbox-label {
                margin-top: 0;
            }

            &__input {
                padding: v(size-10) v(size-16);
            }

            .levelx-button {
                align-self: flex-start;
            }
        }
    }

    &--open {
        z-index: 110;

        .inline-cart__button {
            z-index: 1;

            @include mq-from(sm) {
                z-index: 0;
            }
        }
    }

    /* ANIMATION */
    &-toggle-enter-active {
        transition: transform v(duration-appear) v(easing-default);

        &::before {
            transition: opacity v(duration-disappear) v(easing-default);
        }
    }

    &-toggle-leave-active {
        transition: transform v(duration-disappear) v(easing-default);

        &::before {
            transition: opacity v(duration-disappear) v(easing-default);
        }
    }

    &-toggle-enter-from {
        transform: translateX(100%);

        &::before {
            opacity: 0;
        }
    }

    &-toggle-enter-to {
        transform: translateX(0);

        &::before {
            opacity: 1;
        }
    }

    &-toggle-leave-to {
        transform: translateX(100%);

        &::before {
            opacity: 0;
        }
    }

    /* ---------- */
    &-back-enter-active,
    &-forward-enter-active {
        transition: transform v(duration-appear) v(easing-default);
    }

    &-back-leave-active,
    &-forward-leave-active {
        transition: transform v(duration-disappear) v(easing-default);
    }

    &-forward-enter-from {
        transform: translateX(100%);
    }

    &-forward-leave-from,
    &-forward-enter-to {
        transform: translateX(0);
    }

    &-forward-leave-to {
        position: absolute;
        width: 100%;
        transform: translateX(-100%);
    }

    &-back-enter-from {
        transform: translateX(-100%);
    }
    &-back-enter-from {
        transform: translateX(-100%);
    }

    &-back-leave-from,
    &-back-enter-to {
        transform: translateX(0%);
    }

    &-back-leave-to {
        position: absolute;
        width: 100%;
        transform: translateX(100%);
    }
}
</style>
