<template>
    <div class="slideshow" ref="slideshow">

        <swiper
            :class="{
                'slideshow--has-navigation': hasNavigation,
                'slideshow--has-autoplay': autoplay,
            }"
            :modules="swiperModules"
            autoHeight
            :style="componentStyle"
            watchSlidesProgress
            slidesPerView="auto"
            :spaceBetween="68"
            :loop="true"
            :parallax="true"
            effect="creative"
            @slideChange="onSlideChange"
            :navigation="{
                prevEl: '.slideshow__button-prev',
                nextEl: '.slideshow__button-next',
            }"
            :creativeEffect="{
                prev: {
                    // will set `translateZ(-400px)` on previous slides
                    translate: [0, 0, -400],
                },
                next: {
                    // will set `translateX(100%)` on next slides
                    translate: ['100%', 0, 0],
                    scale: 0.8,
                },
            }"

            v-bind="autoplayConfig"
        >

            <slot />
            <slot name="navigation" />
        </swiper>

    </div>
</template>

<script>
import { Navigation, Parallax, EffectCreative, Autoplay } from 'swiper';
import { useCssVar } from '@vueuse/core';
import { ref } from 'vue';

export default {

    name: 'Slideshow',

    props: {
        autoplay: {
            type: Boolean,
            default: true,
        }
    },

    data: () => {
        return {
            swiperModules: [
                Autoplay,
                Parallax,
                EffectCreative,
                Navigation,
            ],
            autoplayDuration: 8000,

        };
    },

    computed: {
        hasNavigation() {
            return this.$slots['navigation-buttons']
                && this.$slots.default()
                && this.$slots.default().length > 1
        },

        showButtons() {

            return this.$slots.default() && this.$slots.default().length > 1;
        },

        componentStyle() {

            return {
                '--autoplay-duration': this.autoplayDuration + 'ms'
            };

        },

        autoplayConfig() {

            if (!this.autoplay) {
                return null;
            }
            return {
                autoplay: {
                    delay: this.autoplayDuration,
                    disableOnInteraction: false
                }
            }
        }
    },

    methods: {
        onSlideChange(swiper) {
            const slide = swiper.slides[swiper.activeIndex];
            if (!slide) { this.setColor(null); return; }

            const color = slide.getAttribute('data-effect-color');
            if (!color) { this.setColor(null); return; }

            this.setColor(color);
        },

        setColor(color) {
            const section = this.$el.closest('section');
            if (!section) { return; }

            section.style.setProperty('--bam-slideshow-bgcolor', color);
        }
    },
}
</script>
