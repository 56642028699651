import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const init = () => {

    const textSectionMediaElements = gsap.utils.toArray(`
        .section-text .media,
        .section-media-content--desktop-text-above .section-media-content__media
    `);

    textSectionMediaElements.forEach(function(elem){
        gsap.fromTo(elem,
            {
                scale: 1.2
            },
            {
                scrollTrigger: {
                    trigger: elem,
                    scrub: true,
                    start: 'top bottom',
                    // markers: true,
                    end: 'center center',
                    invalidateOnRefresh: true
                },
                scale: 1
                // 'width': '100%',
                // 'left': '50%',
                // 'x': '-50%',
            }
        );
    });
}

export default init;




