import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


const textFadeDuration = 0.25;
const textFadeDelay = 0.25;
const textMovementDuration = 1.5;
const animationDistanceForSmallTexts = '25';


function getTextAnimationProps(text, scrollBox) {

    const textHeight = text.scrollHeight;
    const boxHeight = scrollBox.getBoundingClientRect().height

    const textsIsLargerThanBox = true; // textHeight > boxHeight;

    const animationStartY = textsIsLargerThanBox ? (boxHeight / 4) * 2 : animationDistanceForSmallTexts;
    const animationDistance = textsIsLargerThanBox ? textHeight + (boxHeight / 4) / 2 : animationDistanceForSmallTexts;

    return {
        textsIsLargerThanBox,
        animationStartY,
        animationDistance
    }
}


export const init = () => {

    const highlightScrollerSections = gsap.utils.toArray(".section-highlight-scroller");

    highlightScrollerSections.forEach(function(section) {

        const texts = gsap.utils.toArray(section.querySelectorAll('.section-highlight-scroller__text'));

        const steps = texts.length;

        // const stepHeight = texts.reduce((acc, current) => acc + current.scrollHeight, 0);
        // const stepHeight = window.innerHeight;
        const stepHeight = section.getBoundingClientRect().height;

        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: section,
                start: () => "center center",
                end: () => "+=" + stepHeight * (steps + 1),
                pin: section,//'#content',
                scrub: true,
                markers: false,
                toggleClass: { targets: "body", className: "highlight-scroller-active" },
                toggleActions: "play none reverse none",
                invalidateOnRefresh: false,
                anticipatePin: 1,
            }
        });

        texts.forEach((text, i) => {

            const { poiX, poiY, targetX, targetY, zoom, textPosTop, textPosRight, textPosBottom, textPosLeft, } = text.dataset;

            const scrollBox = text.closest('.section-highlight-scroller__content-scroll-container');
            const textProps = getTextAnimationProps(text, scrollBox);

            tl.to('.section-highlight-scroller', {
                ease: 'none',
                '--poi-x': poiX,
                '--poi-y': poiY,
                '--target-x': targetX,
                '--target-y': targetY,
                '--zoom': zoom,
                'duration': 0.75
            }, 'in' + i + '-=0');

            gsap.set(text.closest('.section-highlight-scroller__content'), {
                '--text-pos-top': textPosTop,
                '--text-pos-right': textPosRight,
                '--text-pos-bottom': textPosBottom,
                '--text-pos-left': textPosLeft,
            });


            const startValues = textProps.textsIsLargerThanBox ? {} : { 'display': 'flex', 'align-items' : 'center', 'flex-flow': 'row wrap'}

            gsap.set(scrollBox, startValues)

            tl.fromTo(text.closest('.section-highlight-scroller__content'), {
                'z-index': 1
            }, {
                'z-index': texts.length * (i + 1)
            }, `in${i}+=${textFadeDelay}`)

            tl.fromTo(text,
                {
                    opacity: 0
                },
                {
                    duration: textFadeDuration,
                    ease: 'none',
                    opacity: 1,
                },
                `in${i}+=${textFadeDelay}`
            )
            .fromTo(text,
                {
                    y: () => getTextAnimationProps(text, scrollBox).animationStartY
                },
                {
                    ease: 'none',
                    duration: textMovementDuration,
                    y: () => '-=' + getTextAnimationProps(text, scrollBox).animationDistance
                },
                'in' + i + '+=.25'
            )
            .to(text,
                {
                    ease: 'none',
                    duration: textFadeDuration,
                    opacity: 0
                },
                `in${i}+=${textMovementDuration}`
            );
        });


        tl.to('.section-highlight-scroller', {
            ease: 'none',
            '--poi-x': '50%',
            '--poi-y': '50%',
            '--target-x': '50%',
            '--target-y': '50%',
            '--zoom': 1,
        }, 'in' + steps +  '-=0.25');

    });
}

export default init;
