<script>
export default {
    name: "shopping-list-item",

    inject: [
        "registerShoppingItem",
        "selectBike",
        "bikeSelected",
        "selectWheels",
        "deselectWheels",
        "wheelsSelected",
    ],

    props: {
        type: String,
    },

    created() {
        this.registerShoppingItem(this);
    },
};
</script>

<template>
    <slot
        v-bind="{
            selectBike,
            bikeSelected,
            selectWheels,
            deselectWheels,
            wheelsSelected,
        }"
    />
</template>

<style lang="scss">
@use "@bam/scss" as *;
</style>
