

<template>

    <div :class="['main-navigation', menuClasses]" ref="main-navigation">

        <slot name="language-switch" />
         <span :class="['main-navigation__menu-button hamburger hamburger--elastic', { 'is-active': menuActive }]" @click.prevent="toggle">
            <span class="hamburger-box">
                <span class="hamburger-inner"></span>
            </span>
        </span>


        <div class="main-navigation__content" ref="content">
            <div class="main-navigation__content-wrapper" ref="content-wrapper">
                <slot />
            </div>
        </div>

    </div>

</template>

<script>
import { disableScrolling, enableScrolling } from '@/utils/ScrollBlocker';



export default {

    name: 'MainNavigation',

    data() {
        return {
            menuActive: false,
            isInMobileNavBp: this.$breakpoints.smaller('mobile-menu-bp')
        }
    },

     watch: {

        menuActive(newStateOpen) {

            if (newStateOpen) {
                disableScrolling();
                document.body.classList.add('mobile-menu-open');
            } else {
                enableScrolling();
                document.body.classList.remove('mobile-menu-open');

            }
        },

        isInMobileNavBp(isSmaller) {

            if (!isSmaller && this.menuActive) {
                this.menuActive = false;
            }
        }
    },

    computed: {

        menuClasses() {
            return {
                'main-navigation--content-visible': this.menuActive
            }
        },
    },

    methods: {

        toggle() {
            this.menuActive = !this.menuActive;
        },
    },
}
</script>
