<template>

    <video
        v-bind="$attrs"
        :controls="videoControls"
        :autoplay="videoAutoplay"

        ref="video">
        <source :src="src" type="video/mp4">
    </video>

    <div v-if="playButtonVisible" class="media__play-icon" @click="play">
        <slot name="icon"></slot>
    </div>

</template>

<script>


export default {
    name: 'levelx-video',

    props: {
        src: {
            type: String,
            required: true
        },
        controls: {
            type: Boolean,
            default: false
        },
        autoplay: {
            type: Boolean,
            default: true
        },
    },



    data() {
        return {
            started: false
        }
    },

    computed: {

        videoControls() {
            return this.started && this.controls;
        },

        videoAutoplay() {
            return this.autoplay;
        },

        playButtonVisible() {
            return !this.started && !this.videoAutoplay;
        }
    },


    methods: {

        play() {
            this.$refs.video.play();
            this.started = true;
        }
    }

}
</script>
