<template>
    <swiper
        class="overlay-slideshow"
        :modules="swiperModules"
        autoHeight
        :style="componentStyle"
        :spaceBetween="0"
        :loop="true"
        :pagination="{ clickable: true }"
        effect="move"
        :initialSlide="activeIndex"
    >
        <slot />
    </swiper>
</template>

<script>
import { Parallax, EffectFade, Autoplay, Pagination } from 'swiper';

export default {

    name: 'OverlaySlideshow',

    props: {
        activeIndex: {
            type: Number,
            deault: 0
        }
    },

    data: () => {
        return {
            swiperModules: [
                Autoplay,
                Parallax,
                EffectFade,
                Pagination,
            ],
            autoplayDuration: 10000,
        };
    },

    computed: {

        componentStyle() {

            return {
                // '--autoplay-duration': this.autoplayDuration + 'ms'
            };

        },

        autoplayConfig() {
            return {
                delay: this.autoplayDuration,
                disableOnInteraction: false
            }
        }
    },
}
</script>
