<template>

    <swiper
        :class="['content-slideshow-navigation', 'section-content-slideshow__navigation-slider', navSliderClasses]"
        :modules="swiperModulesNavigationSlider"
        :slides-per-view="'auto'"
        @swiper="setNavigationSlider"
    >

        <swiper-slide class="content-slideshow-navigation__slide" v-for="comp, index in navigationElements" :key="index">
            <component  :is="comp"  />
        </swiper-slide>

    </swiper>


    <swiper
        class="content-slideshow section-content-slideshow__slider"
        :modules="swiperModulesContentSlider"
        :slides-per-view="1.5"
        :space-between="20"
        :auto-height="true"
        :centered-slides="true"
        :centered-slides-bounds="true"
        :center-insufficient-slides="true"
        :thumbs="{ swiper: navigationSlider }"
        :breakpoints="{
            769: {
                slidesPerView: 1.3,
                spaceBetween: 42,
            }
        }"
        :navigation="{
            prevEl: '.section-content-slideshow__button-prev',
            nextEl: '.section-content-slideshow__button-next',
        }"
        @slideNextTransitionStart="handleSlideNext"
        @slidePrevTransitionStart="handleSlidePrev"
        @swiper="setSwiper"
    >
        <slot :activeIndex="swiper && swiper.activeIndex" :toggle="handleClick" />

        <template #container-end>
            <slot name="navigation" />
        </template>
    </swiper>

</template>

<script>
import { ref } from 'vue';
import {  FreeMode, Thumbs, Autoplay, Navigation } from 'swiper';


export default {

    name: 'ContentSlideshow',

    inject: [ 'getOverlay' ],

    props: {
        overlayId: String,
    },

    data: () => {
        return {
            swiperModulesNavigationSlider: [ FreeMode ],
            swiperModulesContentSlider: [ Thumbs, Autoplay, Navigation ],
            swiper: null,
        };
    },

    setup() {

        const navigationSlider = ref(null);
        const slideDirection = ref('next');

        const setNavigationSlider = swiper => navigationSlider.value = swiper;

        const handleSlideNext = swiper => slideDirection.value = 'next';
        const handleSlidePrev = swiper => slideDirection.value = 'prev';

        return {
            navigationSlider,
            slideDirection,
            setNavigationSlider,
            handleSlideNext,
            handleSlidePrev,
        };
    },

    computed: {

        navigationElements() {
            return this.$slots['navigation-elements'] && this.$slots['navigation-elements']();
        },

        navSliderClasses() {
            return {
                [`direction-${this.slideDirection}`]: this.slideDirection
            }
        },
    },

    methods: {
        setSwiper(swiper) {
            this.swiper = swiper;
        },

        handleClick(event) {

            if (!this.overlayId) { return; }

            event.preventDefault();

            const overlay = this.getOverlay(this.overlayId);

            if (overlay) {
                overlay.openOverlay();
            }
        }
    }

}
</script>
