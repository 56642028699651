import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const init = () => {
    const sections = gsap.utils.toArray(".section-abslider");

    sections.forEach(function (section) {
        const abslider = section.querySelector(".abslider");

        const styles = abslider.getAttribute("style");
        // Search for variable that contains: -handleX
        const found = /(--.*):/g.exec(styles);
        let cssVar = found && found.length > 1 ? found[1] : null;

        if (!cssVar) {
            return;
        }

        ScrollTrigger.create({
            trigger: abslider,
            invalidateOnRefresh: true,
            start: "bottom bottom",
            onEnter: function () {
                if (abslider.classList.contains("done")) {
                    return;
                }
                gsap.fromTo(
                    abslider,
                    {
                        [cssVar]:
                            getComputedStyle(abslider).getPropertyValue(cssVar),
                    },
                    {
                        [cssVar]: "100%",
                        duration: 1.25,
                        ease: "expo",
                        onComplete: function () {
                            gsap.to(abslider, {
                                [cssVar]: "50%",
                                ease: "expo",
                            });
                            abslider.classList.add("done");
                        },
                    }
                );
            },
        });

        // ScrollTrigger.create(abslider, {
        //     interval: 0.1,
        //     batchMax: 3,
        //     invalidateOnRefresh: true,
        //     start: () => 'top bottom',
        //     end: () => 'bottom top',
        //     refreshPriority: -1,
        //     onEnter: batch => gsap.to(batch, { autoAlpha: 1, y: 0, stagger: 0.15, overwrite: true, ease: "expo", duration: 1.25}),
        //     onLeaveBack: batch => gsap.set(batch, { autoAlpha: 0, y: '300px', stagger: 0, overwrite: true, ease: "expo", duration: 0}),
        //     onEnterBack: batch => gsap.to(batch, { autoAlpha: 1, y: 0, stagger: 0.15, overwrite: true, ease: "expo", duration: 1.25}),
        //     onLeave: batch => gsap.set(batch, { autoAlpha: 0, y: '-300px', stagger: 0, overwrite: true, ease: "expo", duration: 0})
        // });
    });
};

export default init;
