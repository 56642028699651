<template>

    <nav class="section-blog-post-content__inline-navigation">

        <a v-for="anchor in anchors" :key="anchor.href" :class="['section-blog-post-content__inline-link', anchorLinkStyle(anchor.id)]" :href="anchor.href" :alt="anchor.title">
            <span class="section-blog-post-content__inline-link-label" v-html="anchor.title"></span>
        </a>

    </nav>

</template>

<script>



export default {
    name: 'TOC',

    props: {
        anchors: {
            type: Object,
            required: true
        }
    },

    data() {
        return {

            activeElement: null,
            anchorStates: {},
            observer: new IntersectionObserver(this.setCurrent, {
                root: document,
                rootMargin: "0px 0px 0px 0px",
                threshold: [1],
            })
        }
    },

    computed: {

        anchorLinkStyle() {
            return (id) => ({
                'section-blog-post-content__inline-link--active': this.activeElement == id
            });
        }
    },

    mounted() {

        this.anchors.forEach(anchor => {
            const element = document.querySelector(anchor.href);

            if (element) {
                this.observer.observe(element);
            }
        });

        this.observer.takeRecords();
    },

    methods: {

        setCurrent(entries) {

            entries.forEach(e => {
                this.anchorStates[e.target.id] = e;
            });

            let id = null;

            if (entries.every(e => !e.isIntersecting)) {

                const elementsAboveViewport = entries.filter(el => el.boundingClientRect.y < 0);
                const lastElement = elementsAboveViewport[elementsAboveViewport.length - 1];

                id = lastElement ? lastElement.target.id : this.anchors[0].id;
            }

            const states = Object.entries(this.anchorStates);
            const active = states.find(e => e[1].isIntersecting);

            if (active) {

                id = active[0];

            } else {

                const lastIdx = states.findIndex(e => e[0] == this.activeElement);

                const last = states[lastIdx];
                const prev = states[lastIdx - 1];

                id = prev ? (last[1].boundingClientRect.y > 0 && prev[1].boundingClientRect.y < 0 ? prev[0] : this.activeElement) : id;
            }

            this.activeElement = id;
        }
    }

}
</script>
