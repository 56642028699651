<template>

    <component
        :is="tag"
        :class="['levelx-button', buttonClasses]"
        :disabled="disabledAttr"
        :tabindex="tabindexAttr"
        @click="handleClick"
    >

        <span v-if="$slots['icon'] && !loading" class="levelx-button__icon">
            <slot name="icon" />
        </span>

        <span v-if="$slots['default']" class="levelx-button__label">
            <slot v-bind="{ label }">{{ label }}</slot>
        </span>

        <span v-if="loading" class="levelx-button__icon levelx-button__loading-indicator">
            <slot name="loading-indicator">
                <svg class="levelx-button__default-loading-spinner" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="50" cy="50" r="45"/>
                </svg>
            </slot>
        </span>

    </component>

</template>

<script>

export default {

    name: 'LevelxButton',

    inject: [ 'getOverlay' ],

    props: {
        tag: {
            type: String,
            default: 'a'
        },
        label: {
            type: String
        },
        iconPosition: {
            type: String,
            default: 'left'
        },
        size: {
            type: String,
            default: 'medium'
        },
        variant: {
            type: String,
            default: 'solid'
        },
        color: {
            type: String,
            default: 'primary'
        },
        inverted: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
    },

    computed: {

        buttonClasses() {
            return [
                `levelx-button--size-${this.size}`,
                `levelx-button--variant-${this.variant}`,
                `levelx-button--color-${this.color}`,
                `levelx-button--icon-position-${this.iconPosition}`,
                {
                    'levelx-button--inverted': this.inverted,
                    'levelx-button--loading': this.loading,
                },
            ];
        },

        disabledAttr() {
            return this.disabled ? 'disabled' : null;
        },

        tabindexAttr() {
            return this.disabled ? '-1' : null;
        }
    },

    created() {

    },

    methods: {

        handleClick(event) {

            if (this.disabled) {
                event.preventDefault();
                return false;
            }

            if (this.$attrs['data-overlay'] && this.$attrs['data-overlay'].trim().length > 0) {

                event.preventDefault();

                const overlayId = this.$attrs['data-overlay'].trim()
                const overlay = this.getOverlay(overlayId);

                if (overlay) {
                    overlay.openOverlay();
                }

            }
        }
    }
}
</script>
