import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


function animateFrom(elem, direction) {

    direction = direction || 1;

    let x = 0;
    let y = direction * 100;

    const fromLeft = elem.closest('.section-media-content')?.classList.contains('section-media-content--desktop-media-first') // from left
    const fromRight = elem.closest('.section-media-content')?.classList.contains('section-media-content--desktop-text-first') // from right

    elem.style.transform = "translate(" + x + "px, " + y + "px)";
    elem.style.opacity = "0";



    if (elem.classList.contains('media')) {

        if (fromLeft) {
            x = -100;
            y = 0;
        } else if (fromRight) {
            x = 100;
            y = 0;
        }
    }

    gsap.fromTo(elem, { x: x, y: y, autoAlpha: 0 }, {
      duration: 1.25,
      x: 0,
      y: 0,
      autoAlpha: 1,
      ease: "expo",
      overwrite: 'auto'
    });

  }

  function hide(elem) {
    gsap.set(elem, { autoAlpha: 0 });
  }


export const init = () => {


    const textElements = gsap.utils.toArray(`
        .section-intro > *,
        .section-media-content--desktop-media-first .section-media-content__media,
        .section-media-content--desktop-text-first .section-media-content__media,
        .section-media-content__text > *,
        .section-media-content__content > *,
        .section-media-content__button-group > *,

        .section-big-link__text > *,
        .section-big-link__link,
        .section-text__headline > *,
        .section-text__text > *,


        .section-text-only__text > *,
        .text-media-slideshow__text > *,
        .section-cta__text > *,

        .section-newsletter-form__text > *,
        .newsletter-from > *,
        .section-contact__text > *,
        .section-contact__address address p > *,
        .contact-form > *,

        .section-text-media .levelx-button,
        .section-text .levelx-button,

        .section-text-only .levelx-button,

        .section-accordion__text > *
        `);
        // .section-accordion .accordion-panel
        // .section-text .levelx-button


    textElements.forEach(elem => {

        hide(elem); // assure that the element is hidden when scrolled into view

        elem.style.opacity = 0;
        elem.style.visibility = 'hidden';
        elem.style.willChange =  'transform, opacity';

        ScrollTrigger.create({
          trigger: elem,
          invalidateOnRefresh: true,
        //   markers: true,
          start: 'top bottom',
          onEnter: function() { animateFrom(elem) },
          onEnterBack: function() { animateFrom(elem, -1) },
          onLeave: function() { hide(elem) }, // assure that the element is hidden when scrolled into view
          onLeaveBack: function() { hide(elem) } // assure that the element is hidden when scrolled into view
        });
      });

}

export default init;
