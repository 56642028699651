import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

// Vielleicht doch besser so?
// https://codepen.io/akapowl/pen/abmpGQv/d076a9cf4c1a952a0bc921a76bc2202f

export const init = () => {
    const sections = gsap.utils.toArray(".section-model-scroller");

    sections.forEach((section) => {
        const panelsContainer = section.querySelector(
            ".section-model-scroller__panel-scroller"
        );
        const panels = [
            ...panelsContainer.querySelectorAll(
                ".section-model-scroller__panel"
            ),
        ];
        const texts = [
            ...panelsContainer.querySelectorAll(
                ".section-model-scroller__text"
            ),
        ];

        const scrollTween = gsap.fromTo(
            panels,
            {
                ease: "none",
                xPercent: -100 * (panels.length - 1),
            },
            {
                xPercent: 0,
                ease: "none",
                scrollTrigger: {
                    trigger: panels,
                    pin: section,
                    start: () => "center center",
                    end: () => {
                        return "+=" + section.scrollWidth * 2;
                    },
                    scrub: 0.1,
                    // markers: true,
                    anticipatePin: 1,
                    invalidateOnRefresh: true,
                },
            }
        );

        gsap.set(texts, { y: 30, opacity: 1 });

        texts.forEach((text, i) => {
            gsap.to(text, {
                y: 0,
                autoAlpha: 1,
                ease: "none",
                scrollTrigger: {
                    trigger: text,
                    containerAnimation: scrollTween,
                    start: "top top",
                    end: "bottom bottom",
                    // scrub: true,
                },
            });
        });
    });
};

export default init;
