import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const init = () => {

    const sections = gsap.utils.toArray(".section-big-image-cta");

    sections.forEach((section) => {
        const elem = section.querySelector('.media__media-element');

        gsap.fromTo(elem,
            {
                filter: "blur(0px)"
            },
            {
                scrollTrigger: {
                    trigger: elem,
                    scrub: true,
                    start: 'top 70%',
                    end: 'center center',
                    invalidateOnRefresh: true
                },
                filter: "blur(20px)"
            }
        );
    });
}

export default init;
